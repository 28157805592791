import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Nav from "../../Common/Navbar/index";

// AOS Animation
import AOS from "aos";
import "aos/dist/aos.css";
import About from "../About";
import Service from "../service";
import Team from "../team";
import Clients from "../clients";
import FAQ from "../faq";
import FooterSection from "../footer";
import { FaUser } from "react-icons/fa";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosMail, IoLogoWhatsapp } from "react-icons/io";

const Home = () => {
  const [showButtons, setShowButtons] = useState(false);

  const showClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setShowButtons(!showButtons);
  };

  useEffect(() => {
    AOS.init({});
  }, []);


  return (
    <>
      <Nav />

      <div className="fixed-button">
        <a href="/"  className="btn" onClick={showClick}>
          <FaUser />
        </a>
        {showButtons && (
          <div className="additional-buttons" data-aos="fade-left">
            <a href="tel:+8269907262" className="btn additional-btn top">
              <BiSolidPhoneCall />
            </a>
            <a href="https://wa.me/8269907262" target="_blank" rel="noopener noreferrer" className="btn additional-btn right">
              <IoLogoWhatsapp />
            </a>
            <a href="mailto:skill.intelligence7@gmail.com" className="btn additional-btn bottom">
              <IoIosMail />
            </a>
          </div>
        )}
      </div>

      <section
        className="gradient-overly-right bg-animate h-screen flex flex-col justify-center items-center"
        id="Home"
      >
        <div className="container mx-auto">
          <section className="gap-6 grid-cols-1">
            <div
              className="text-center"

            >
              <p className="home-content mb-2 text-1xl font-extrabold text-white lg:text-xl">
                Welcome To Our Website
              </p>
              <h1 className="home-head mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl dark:text-white">
                Skill Intelligence World
              </h1>
              <p className="home-content mb-5 text-lg font-normal text-white lg:text-xl sm:px-16 lg:px-48">
                Our company operates in the education and professional
                development sector, focusing on delivering high-quality training
                programs,
              </p>
            </div>
          </section>
        </div>
      </section>
      <About />
      <Service />
      <Team />
      <Clients />
      <FAQ />
      <FooterSection />
    </>
  );
};

export default Home;
